import Hero from "./Hero";
import Medium from "./Medium";

const HomeScreen = () =>{
  return(
    <div>
        <Hero/>
        <Medium/>
    </div>
  ) 
}

export default HomeScreen;